// Designed to work with Socket.io
//
// Usage example:
// var client = new PubsubClient("server1");
// client.subscribe("channel1", "event1", function(data) { /* do something */ });

export class PubsubClient {
	constructor(server, enableLogs = false) {
		this.server = server;
		this.subscriptions = {};
		this.enableLogs = enableLogs;
		this.connect();
	}

	connect() {
		this.client = io(this.server, {
			transports: ['websocket']
		});
	
		this.client.on('connect', this.onConnect.bind(this));
		this.client.on('disconnect', this.onDisconnect.bind(this));
		this.client.on('message', this.onMessage.bind(this));
	}
	
	// Connect / Disconnect
	onConnect() {
		this.log("Connected.");
		this.resubscribe();
	}

	onDisconnect() {
		this.log("Disconnected.");
	}
	
	// Message handling
	onMessage(data) {
		this.log(`Message: ${JSON.stringify(data)}`);

		if (data.type == "event") {
			this.onEvent(data.channel, data.eventName, data.eventData);
		} else {
			this.log("Received unknown message.");
			this.log(data);
		}
	}

	// Subscriptions and Events
	subscribe(channel, event, callback) {
		this.log(`Subscribing to ${channel} - ${event}`);

		if (!this.subscriptions[channel]) {
			this.subscriptions[channel] = {};
			this.client.emit('subscribe', channel);
		}
	
		var subs = this.subscriptions[channel][event] || [];
		subs.push(callback);
		this.subscriptions[channel][event] = subs;
	}

	unsubscribe(channel, event, callback) {
		this.log(`Unsubscribing from ${channel} - ${event}`);

		var subs = this.subscriptions[channel]?.[event] || [];
		subs = _.without(subs, callback);
		this.subscriptions[channel][event] = subs;
	
		if (subs.length === 0) {
			delete this.subscriptions[channel][event];

			if (Object.keys(this.subscriptions[channel]).length === 0) {
				delete this.subscriptions[channel];
				this.client.emit('unsubscribe', channel);
			}
		}
	}

	resubscribe() {
		this.log("Resubscribing to all channels."); // Added this log
		for (let channel in this.subscriptions) {
			this.client.emit('subscribe', channel);
		}
	}

	onEvent(channel, event, data) {
		var subs = this.subscriptions[channel]?.[event] || [];
		subs.forEach(cb => {
			try {
				cb(data);
			} catch(e) {
				this.log(`Error on callback - channel: ${channel}, event: ${event}`);
			}
		});
	}

	// Misc.
	log(msg) {
		if (this.enableLogs) {
			console.log("[PubsubClient]", msg);
		}
	}
}
