// 
// Common
import string from "./common/string_utilities";
import generalUtilities from "./common/general_utilities"

// 
// Shoelace
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import "@shoelace-style/shoelace/dist/components/icon/icon.js"
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/checkbox/checkbox.js';
import '@shoelace-style/shoelace/dist/components/tag/tag.js';
import '@shoelace-style/shoelace/dist/components/radio/radio.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import '@shoelace-style/shoelace/dist/components/select/select.js';
import '@shoelace-style/shoelace/dist/components/option/option.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/skeleton/skeleton.js';
import '@shoelace-style/shoelace/dist/components/copy-button/copy-button.js';
import '@shoelace-style/shoelace/dist/components/tab/tab.js';
import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js';
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js';


import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.13.1/cdn/');
Vue.config.ignoredElements = [/sl-/];

// 
// Global imports
import jQuery from 'jquery'
window.jQuery = jQuery;
window.$ = jQuery;

import getBrowserFingerprint from "./libs/fingerPrint";
window.getBrowserFingerprint = getBrowserFingerprint

import _ from "lodash"
window._ = _;

import Bowser from 'bowser';
window.Bowser = Bowser;

import { io } from 'socket.io-client';
window.io = io;

import { PubsubClient } from './libs/pubsub_client';
window.PubsubClient = PubsubClient;

window.generalUtilities = generalUtilities;

// Helpers (need to move to a better place)
window.isMobile = (window.innerWidth < 768);
window.isInsideApp = (typeof(MobileChannel) != "undefined");
if (window.isInsideApp) {
  window.MobileChannel = MobileChannel;
}
